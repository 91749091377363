//import manager from '@/utils/manager'
import api from '@/utils/api'

export function accountInfo(params) {
    return api.get('wx/v1/rxdld/account', { params:params })
}

export function getServerList(params) {
    return api.get('wx/v1/rxdld/get_server', { params:params })
}

export function getRoleList(params) {
    return api.get('wx/v1/rxdld/get_role', { params:params })
}
export function getGameId(params) {
    return api.get('wx/v1/rxdld/get_game', { params:params })
}
export function payThunderSnake(params) {
    return api.post('thundersnakePay/pay', params)
}

export function payLianLian(params) {
    return api.post('lianLianPay/pay', params)
}

export function stripeMoneyList(params) {
    return api.post('stripe/moneyList', params)
}
export function thirdProductList(params) {
    return api.post('wx/v1/rxdld/moneyList', params)
}

//  mycard台湾支付
export function payMycard(params) {
    return api.post('mycardPay/pay', params)
}


export function pay(params) {
    // return api.post('wx/v1/fenghuo/pay', params)
    params.project_id = 'fenghuo';
    params.type = 2;
    return api.post('wx/v1/official/common/wxpay', params)
}

export function commonCreateOrder(params) {
    return api.post('wx/v1/rxdld/common/jzac', params)
}

//原生H5页面内部下单
export function commonCreateOrderH5(params) {
    return api.post('wx/v1/rxdld/common/creatdldorder', params)
}

//美元下单
export function commonCreateOrderUsd(params) {
    return api.post('wx/v1/rxdld/common/createusorder', params)
}

//获取美元价格
export function getUsdMoney(params) {
    return api.get('wx/v1/rxdld/common/dldusdmoney', { params:params })
}

//支付包支付或paypel支付
export function payMix(params) {
    // return api.post('wx/v1/fenghuo/pay/mix', params)
    return api.post('wx/v1/official_account/pay', params)
}

//公众号微信支付
export function payWxRedirect(orderNum) {
    // return api.post('wx/v1/fenghuo/pay/wxRedirect', { order_num: orderNum })

    //    最新版公众号微信支付
    return api.post('wx/v1/official/common/wxpay', { order_num: orderNum, type: 1, project_id: 'fenghuo' })
}

//获取打金传说的活动
export function discountActivity(params)
{
    return api.post('wx/v1/rxdld/discount/activity', params)
}

//根据订单号查询美元价格
export function getOrderUsdMoney(params) {
    return api.get('/common/getUsdMoney',  { params:params })
}