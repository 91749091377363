<template>
  <div>
    <div class="product_Name">{{ product_Name }}</div>
    <div v-if="moneyText" class="product_Name">{{ locale == 'us' ? 'Money(USD):' + moneyText : '金额(USD):' + moneyText }}</div>
    <div class="product_Name colorRed">{{ locale == 'us' ? '5% discount for recharge' : '充值九五折'}}</div>
    <van-form @submit="onSubmit">
      <div v-if="payTypeId == 20 && (!user_email || user_email == '')">
        <van-field v-model="first_name" maxlength="10" name="first_name" :label="locale == 'us' ? 'first name' : '姓'"
          :placeholder="locale == 'us' ? 'please enter your first name' : '请输入姓'" :rules="[{ required: true }]" />
        <van-field v-model="last_name" maxlength="10" name="last_name" :label="locale == 'us' ? 'last name' : '名字'"
          :placeholder="locale == 'us' ? 'please enter your last name' : '请输入名字'" :rules="[{ required: true }]" />
        <van-field v-model="email" name="email" maxlength="30" label="email"
          :placeholder="locale == 'us' ? 'please enter your email' : '请输入邮箱'"
          :rules="[{ required: true },
          { pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: locale == 'us' ? 'Please enter the correct email address' : '请输入正确邮箱' }]" />
      </div>
      <van-notice-bar v-show="discount_show == true" wrapable :scrollable="false" :text="discount_info" />

      <!--支付方式-->
      <van-cell :title="locale == 'us' ? 'Mode of Payment' : '支付方式'" style="text-align: center"></van-cell>

      <van-radio-group v-model="payTypeId">
        <van-cell-group>
          <!-- 微信 -->
          <van-cell v-if="locale == 'zh'" clickable @click="payTypeId = '2'">
            <van-image class="pay_icon" :src="require('@/assets/credit/pay_icon/wechat.png')" />
            <div class="pay_desc">（支持人民币）</div>
            <template #right-icon>
              <van-radio name="2" />
            </template>
          </van-cell>
          <!-- 支付宝 -->
          <van-cell v-if="locale == 'zh'" clickable @click="payTypeId = '1'">
            <van-image class="pay_icon" :src="require('@/assets/credit/pay_icon/alipay.png')" />
            <div class="pay_desc">（支持人民币）</div>
            <template #right-icon>
              <van-radio name="1" />
            </template>
          </van-cell>

          <!-- 谷歌支付-->
          <van-cell clickable @click="payTypeId = '21'">
            <van-image class="pay_icon" :src="require('@/assets/credit/pay_icon/google_us.png')" />
            <div class="pay_desc">
              <span>{{ locale == 'us' ? 'the bill amount is in US dollars' : '（支持外币，账单显示金额为美元 USD）' }}</span>
            </div>
            <template #right-icon>
              <van-radio name="21" />
            </template>
          </van-cell>

          <!-- apple支付-->
          <van-cell clickable @click="payTypeId = '22'">
            <van-image class="pay_icon" :src="require('@/assets/credit/pay_icon/apple_us.png')" />
            <div class="pay_desc">
              <span>{{ locale == 'us' ? 'the bill amount is in US dollars' : '（支持外币，账单显示金额为美元 USD）' }}</span>
            </div>
            <template #right-icon>
              <van-radio name="22" />
            </template>
          </van-cell>

          <!-- paypay-->
          <van-cell clickable @click="payTypeId = '3'">
            <van-image class="pay_icon" :src="require('@/assets/credit/pay_icon/paypal.png')" />
            <div class="pay_desc">
              <span>{{ locale == 'us' ? 'the bill amount is in US dollars' : '（支持外币，账单显示金额为美元 USD）' }}</span>
            </div>
            <template #right-icon>
              <van-radio name="3" />
            </template>
          </van-cell>
          <!-- Airwallex支付-->
          <van-cell clickable @click="payTypeId = '20'">
            <van-image class="pay_icon" :src="require('@/assets/credit/pay_icon/pCardUs3.png')" />
            <div class="pay_desc">
              <span>{{ locale == 'us' ? 'the bill amount is in US dollars' : '（支持外币，账单显示金额为美元 USD）' }}</span>
            </div>
            <template #right-icon>
              <van-radio name="20" />
            </template>
          </van-cell>
          <!-- stripe支付-->
          <!-- <van-cell clickable @click="payTypeId = '10'">
            <van-image class="pay_icon" :src="require('@/assets/credit/pay_icon/pCardUs2.png')" />
            <div class="pay_desc">
              <span>the bill amount is in US dollars</span>
            </div>
            <template #right-icon>
              <van-radio name="10" />
            </template>
          </van-cell> -->
          <!-- paypay银行卡-->
          <van-cell clickable @click="payTypeId = '14'">
            <van-image class="pay_icon" :src="require('@/assets/credit/pay_icon/pCardUs.png')" />
            <div class="pay_desc">
              <span>{{ locale == 'us' ? 'the bill amount is in US dollars' : '（支持外币，账单显示金额为美元 USD）' }}</span>
            </div>
            <template #right-icon>
              <van-radio name="14" />
            </template>
          </van-cell>
          <!-- mycard -->
          <van-cell clickable @click="payTypeId = '15'" v-show="mycardShow">
            <van-image class="pay_icon" :src="require('@/assets/common/mycard.png')" />
            <div class="pay_desc"><span>{{ locale == 'us' ? 'support dollars' : ' （支持外币，台币）' }}</span></div>
            <template #right-icon>
              <van-radio name="15" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>

      <div style="margin: 16px">
        <van-button style="width: 45%;float:left" type="primary">{{ locale == 'us' ? 'Recharge Now' : '立即充值'
        }}</van-button>
        <van-button style="width: 45%; float: right" type="info" @click="reset" native-type="button">{{ locale == 'us' ?
          'Reset' : '重置' }}
        </van-button>
      </div>
    </van-form>
    <van-cell :title="locale == 'us' ? 'PS:After successful recharge, please collect the items in the game email':'PS:充值成功后,道具请到游戏邮件中领取'"
      style="color: red;text-align: center" />
    <van-cell :title="locale == 'us' ? 'the exchange rate is based on real-time exchange rate' : '外币结算汇率按照实际汇率实时变动'"
      style="color: red;text-align: center" />
    <!--    <div style="float: right;margin-top: -60px;margin-right: 10px">-->
    <!--      <van-icon class="iconfont" class-prefix="icon" name="zhongyingwenqiehuan-zhongwen"  size="40" color="#0066FF" @click="changeCn"/>-->
    <!--    </div>-->
    <!-- 优惠券列表 -->
    <van-popup v-model="showCouponList" round position="bottom" style="height: 90%; padding-top: 4px">
      <van-coupon-list :coupons="coupons" :chosen-coupon="chosenCoupon" :disabled-coupons="disabledCoupons"
        :show-exchange-bar="false" @change="onCouponChange" />
    </van-popup>
  </div>
</template>

<script>
import {
  commonCreateOrderH5,
  payMix,
  payWxRedirect,
  getUsdMoney,
  // stripeMoneyList,
  payThunderSnake,
  payLianLian,
  payMycard,
  getOrderUsdMoney
} from "@/api/rxdld";
import {
  stripePayment,
  AirwallexPayment,
} from "@/api/stripePay";
import { Toast } from "vant";
import { Base64 } from "js-base64";
import { couponList } from "@/api/user";
import { redirectToCheckout, loadAirwallex } from 'airwallex-payment-elements';
// import { defineComponent } from "vue";
import { isSysName } from "@/utils";
loadAirwallex({
  env: 'prod', // Can choose other production environments, 'staging | 'demo' | 'prod'
});
const PAY_Airwallex = 20
const PAY_TYPE_WX = 2
const PAY_TYPE_ALIPAY = 1
const PAY_TYPE_PAYPAL = 3
const PAY_TYPE_WIN = 11
const PAY_THUNDER_SNAKE = 12
const PAY_LIAN_LIAN = 13
const PAY_PAL_CARD = 14
const PAY_MYCARD_CARD = 15
const PAY_STRIPE = 10
const PAY_Google = 21
const PAY_Apple = 22
export default {
  data() {
    return {
      first_name: '',
      last_name: '',
      email: '',
      account: "", //账号
      test_show: true,
      accountList: [],
      mycardShow: true,//  mycard测试
      accountShowPicker: false,
      user_id: 0, //用户id
      user_slug:'',//用户slug
      user_email: '',
      game_user_id: 0, //游戏用户id
      game_id: "", //游戏id
      is_sale: 0, //是否打折 0不打折
      sale_info: "", //销售信息
      discount_info: "恭喜你，本次充值额外赠送10%！", //折扣信息
      discount_show: false, //显示打折信息
      discountTips: "", //  获取折扣信息[此字段：正式使用时，初始化值为空，通过接口获取该值（包括折扣信息）]
      discountTipsShow: false, //  获取折扣信息[此字段：正式使用时，初始化值为空，通过接口获取该值（包括折扣信息）]
      money: '', //用户充值金额
      product_id: 0, //后台自增id 非product_id
      product_name: "吉祥宝钱", //物品名
      payState: false, //防止重复点击
      server_id: "", //选中区服ID
      role_id: 0, //选中角色ID
      serverText: "", //选中区服
      getServerList: [], //区服列表
      serverLoading: false,
      serverShowPicker: false,
      roleText: "", //选中角色
      roleList: [], //角色列表
      roleLoading: false,
      roleId: 0,
      roleShowPicker: false,
      moneyText: "", //选中金额
      moneyList: [], //金额列表
      moneyLoading: false,
      moneyShowPicker: false,
      payTypeId: "3",
      chosenCoupon: -1,
      showCouponList: false,
      coupons: [],
      couponList: [],
      disabledCoupons: [],
      couponId: undefined,
      discount: false,
      discountText: "",
      product_Name: '',
      locale: 'us',
      lang: 'en',
      goodId:'',
      goodType:'',
      cpOrderId:'',
    };
  },
  watch: {
    // 监听路由对象中的'$route'属性
    '$route': {
      handler: function (to, from) {
        console.log(to)
        console.log(from)
        // 当URL参数变化时，这里的代码会被执行
        location.reload()
      },
      // 设置为深度监听，以便观察路由对象内部的属性变化
      deep: true
    }
  },
  mounted: function () {
    const img = document.querySelector(`img[src="https://wx.playhy.com/card-mark.png"]`);
    if (img) {
      img.remove()
    }
    const p = document.querySelector(`p`);
    if (p) {
      p.remove()
    }
  },
  created() {
    console.log(this.$route.query)
    this.user_slug = decodeURIComponent(this.$route.query.user_id);
    this.game_user_id = decodeURIComponent(this.$route.query.game_user_id);
    // this.game_id = this.$route.query.game_id;
    this.game_id = '34988e070dc049819984412668601753';
    this.server = decodeURIComponent(this.$route.query.server);
    this.server_id = decodeURIComponent(this.$route.query.server_id);
    this.role_name = decodeURIComponent(this.$route.query.role_name);
    this.product_id = decodeURIComponent(this.$route.query.product_id);
    this.money = decodeURIComponent(this.$route.query.money);
    this.product_Name = decodeURIComponent(this.$route.query.product_Name);
    // this.goodId = decodeURIComponent(this.$route.query.goodId);
    // this.goodType = decodeURIComponent(this.$route.query.goodType);
    this.cpOrderId = decodeURIComponent(this.$route.query.cpOrderId);
    
    this.locale = this.$i18n.locale;
    this.payTypeId = this.$i18n.locale == 'zh' ? '2' : '3';
    this.lang = this.$i18n.locale == 'zh' ? 'zh-CN' : 'en'
    this.handleGetUsdMoney();

    this.user_email = localStorage.dldH5UserEmail;
  },
  methods: {
    //获取对应美元金额
    handleGetUsdMoney() {
      let params = {
        game_id: this.game_id,
        money: this.money,
        user_slug: this.user_slug,
        lang: this.lang
      }
      getUsdMoney(params).then((response) => {
        console.log(response)
        this.moneyText = response.money_usd;
        this.user_id = response.user_id;
      }).catch((e) => {
        this.$toast.fail(e.message)
      });
    },

    validator(val) {
      if (val >= 10 && val <= 10000) {
        return true
      }
      return false;
    },
    onSubmit() {
      if(this.payTypeId == 22 && isSysName() != 'ios'){
        this.$toast.fail('仅限苹果手机使用')
        return
      }
      console.log("onSubmit");
      const that = this;
      if (that.payState) {
        return false;
      }
      that.payState = true;
      Toast.loading({
        message: "Loading",
        forbidClick: true,
      });
      console.log("payTypeId", parseInt(this.payTypeId));
      // console.log(localStorage.dldus_info);
      this.orderStore()

      //保存用户信息
      let user_email = this.user_email == undefined || this.user_email == '' ? this.email : this.user_email;
      localStorage.dldH5UserEmail = user_email;
    },
    reset: function () {
      this.changeReset("all");
    },
    changeReset(changeSetup) {
      this.product_id = 0;
      switch (changeSetup) {
        case "all":
          this.user_id = 0;
          this.account = "";
        /* falls through */
        case "account":
          this.osId = "";
          this.osText = "";
          console.log("changeSetup account");
        /* falls through */
        case "os":
          this.game_id = 0;
          this.gameText = "";
          this.gameList = [];
          this.sale_info = "";
        /* falls through */
        case "game":
          this.server_id = "";
          this.serverText = "";
          this.getServerList = [];
        /* falls through */
        case "server":
          this.roleText = "";
          this.roleId = 0;
          this.roleList = [];
        /* falls through */
        case "role":
          this.moneyText = this.product_name = "";
          this.moneyList = [];
          this.discount_show = false;
          this.product_id = 0;
          this.payTypeId = "15";
          break;
      }
    },
    onCouponChange(index) {
      this.showCouponList = false
      this.chosenCoupon = index
      if (index >= 0) {
        this.couponId = this.coupons[index]["coupon_id"]
      } else {
        this.couponId = undefined
      }
    },
    getCouponList() {
      couponList({ account: this.account, user_id: this.user_id }).then(
        (response) => {
          this.couponList = response;
          this.coupons = [];
          this.disabledCoupons = [];
          this.chosenCoupon = -1;
        }
      );
    },
    resetMoney() {
      this.moneyText = this.product_name = this.product_id = undefined
      // this.getCouponList()
    },
    // 创建订单
    orderStore() {
      // if (this.product_id == null || this.product_id.length <= 0) {
      //   this.$toast.fail('Please select the gear first')
      //   return
      // }
      const payTypeId = parseInt(this.payTypeId)
      commonCreateOrderH5({
        user_id: this.user_id,
        game_user_id: this.game_user_id,
        game_id: this.game_id,
        server: this.server,
        server_id: this.server_id,
        role_name: this.role_name,
        // product_id: this.product_id,
        // goodId: this.goodId,
        // goodType: this.goodType,
        cpOrderId: this.cpOrderId,
        props_name:this.product_Name,
        // money: payTypeId == 14 ? 1 : this.money,
        money: this.money,
        os: this.osText,
        pay_type_id: payTypeId,
        coupon_id: this.couponId,
        lang: this.lang
      }).then((response) => {
        this.resetMoney()
        this.payState = false;

        // 如果代金券直接减完支付完成
        // if (response.pay_success && response.pay_success === true) {
        //   this.$toast.success('Payment success')
        // } else {
        //   this.toPay(response.number)
        // }
        this.toPay(response.number)
      }).catch(() => {
        this.payState = false
      })
    },
    // 发起支付
    toPay(orderNum) {
      Toast.loading({
        message: "Loading",
        forbidClick: true,
        duration: 0,
      });
      const payTypeId = parseInt(this.payTypeId)
      if (payTypeId === PAY_TYPE_WX) {
        this.payWx(orderNum)
      } else if (payTypeId === PAY_TYPE_ALIPAY) {
        this.payAlipay(orderNum)
      } else if (payTypeId === PAY_MYCARD_CARD) {
        this.payByMyCard(orderNum)
      } else if (payTypeId === PAY_TYPE_PAYPAL) {
        this.payPaypal(orderNum)
      } else if (payTypeId === PAY_LIAN_LIAN) {
        this.payByLianLian(orderNum)
      } else if (payTypeId === PAY_TYPE_WIN) {
        this.payByWin(orderNum)
      } else if (payTypeId === PAY_THUNDER_SNAKE) {
        this.payByThunderSnake(orderNum)
      } else if (payTypeId === PAY_PAL_CARD) {
        this.payPaypalCard(orderNum)
      } else if (payTypeId === PAY_STRIPE) {
        this.payByStripe(orderNum)
      } else if (payTypeId === PAY_Airwallex) {
        this.payByAirwallex(orderNum)
      }else if (payTypeId === PAY_Google) {
        this.googlePay(orderNum)
      } else if (payTypeId === PAY_Apple) {
        this.applePay(orderNum)
      }
    },
    //空中云汇支付
    payByAirwallex(orderNum) {
      let email = this.user_email == '' ? this.email : this.user_email;
      AirwallexPayment({
        order_num: orderNum,
        first_name: this.first_name,
        last_name: this.last_name,
        email: email,
      }).then((response) => {
        console.log(response)

        const intent_id = response.intent_id;
        const client_secret = response.client_secret;
        const currency = response.currency;
        redirectToCheckout({
          env: 'prod',
          mode: 'payment',
          currency,
          intent_id, // Required, must provide intent details
          client_secret, // Required
          theme: {
            // Must provide theme to display the checkout page properly
            fonts: [
              // Customizes the font for the payment elements
              {
                src: 'https://checkout.airwallex.com/fonts/CircularXXWeb/CircularXXWeb-Regular.woff2',
                family: 'AxLLCircular',
                weight: 400,
              },
            ],
          },
          successUrl: 'https://wx.playhy.com/#/paySuccess?url_type=egrxdld', // Must be HTTPS sites
          failUrl: 'https://wx.playhy.com/#/eg/rxdld', // Must be HTTPS sites
        });
      }).catch(() => {
        Toast.clear(true)
        let msg = this.locale == 'us' ? 'Failure to initiate payment' : '发起支付失败'
        this.$toast.fail(msg)
      })
    },
    //stripe支付
    payByStripe(orderNum) {
      stripePayment({
        order_num: orderNum,
        user_id: this.user_id,
        cancel_url: 'https://wx.playhy.com/#/eg/rxdld',
        redirect_url: 'https://wx.playhy.com/#/paySuccess?url_type=egrxdld',
        lang: this.lang
      }).then((response) => {
        console.log(response)
        window.location.href = response.url
      }).catch((e) => {
        Toast.clear(true)
        this.$toast.fail(e.message)
      })
    },
    //连连支付
    payByLianLian(orderNum) {
      payLianLian({
        order_num: orderNum,
        user_id: this.user_id,
        cancel_url: 'https://wx.playhy.com/#/eg/rxdld',
        redirect_url: 'https://wx.playhy.com/#/paySuccess',
        lang: this.lang
      }).then((response) => {
        console.log(response)
        window.location.href = response.url
      }).catch((e) => {
        Toast.clear(true)
        this.$toast.fail(e.message)
      })
    },
    //雷蛇支付
    payByThunderSnake(orderNum) {
      payThunderSnake({
        order_num: orderNum,
        user_id: this.user_id,
        lang: this.lang,
        cancel_url: 'https://wx.playhy.com/#/darkxiyou',
      }).then((response) => {
        console.log(response)
        window.location.href = response.url
      }).catch((e) => {
        Toast.clear(true)
        this.$toast.fail(e.message)
      })
    },
    //win 支付
    // payByWin(orderNum) {
    //   //携带参数跳转到另一个页面
    //   //转换美元
    //   let mmoney = this.money * 0.16;
    //   this.$router.push({name: 'winPayPage', params: {order_num: orderNum, user_id: this.user_id, money: mmoney}})
    // },
    // 微信
    payWx(orderNum) {
      payWxRedirect(orderNum).then((response) => {
        window.location.href = response.url
      }).catch(() => {
        Toast.clear(true)
        let msg = this.locale == 'us' ? 'Failure to initiate payment' : '发起支付失败'
        this.$toast.fail(msg)
      })
    },
    // PayPal
    payPaypal(orderNum) {
      payMix({
        lang: this.lang,
        number: orderNum,
        user_id: this.user_id,
        pay_type: parseInt(this.payTypeId),
        return_url: "https://api.hzarcticwolf.com/third/v1/offaccount/execpaypal?url_type=darkxiyou"
      }).then((res) => {
        //  正式调试
        console.log(res)
        window.location.href = res.url
        Toast.clear(true)
        Toast.loading({
          message: "Loading",
          forbidClick: true,
          duration: 3000,
        });
      }).catch((e) => {
        this.$toast.fail(e.message)
      });
    },
    // PayPal谷歌支付
    googlePay(orderNum) {
      getOrderUsdMoney({
        order_num: orderNum
      }).then((res) => {
        Toast.clear(true)
        let productName = encodeURIComponent(res.props_name)
        let url = `https://wx.playhy.com/pay/googlePay.html?number=${orderNum}&user_id=${this.user_id}&moneyUsd=${res.money_usd}&productName=${productName}&pay_type=${parseInt(this.payTypeId)}&url=rxdldH5`
        window.location.href = url
      }).catch(() => {
        let msg = this.locale == 'us' ? 'Failure to initiate payment' : '发起支付失败'
        this.$toast.fail(msg)
        Toast.clear(true)
      });

    },
    // PayPal苹果支付
    applePay(orderNum) {
      getOrderUsdMoney({
        order_num: orderNum
      }).then((res) => {
        Toast.clear(true)
        let productName = encodeURIComponent(res.props_name)
        let url = `https://wx.playhy.com/pay/apple.html?number=${orderNum}&user_id=${this.user_id}&moneyUsd=${res.money_usd}&productName=${productName}&pay_type=${parseInt(this.payTypeId)}&url=rxdldH5`
        window.location.href = url
      }).catch(() => {
        let msg = this.locale == 'us' ? 'Failure to initiate payment' : '发起支付失败'
        this.$toast.fail(msg)
        Toast.clear(true)
      });
    },
    // PayPal银行卡支付页面请求
    payPaypalCard(orderNum) {
      this.$router.push({
        path: "/paypalcard",
        query: {
          number: orderNum,
          user_id: this.user_id,
          pay_type: parseInt(this.payTypeId),
          url: 'rxdldH5'
        }
      });
      Toast.clear(true)
    },
    // 支付宝
    payAlipay(orderNum) {
      // 跳转中间页
      Toast.clear(true);
      console.log("跳转支付中间页");
      const key = Base64.encode(
        JSON.stringify({
          user_id: this.user_id,
          number: orderNum,
          pay_type: parseInt(this.payTypeId),
        })
      )
      this.$router.push({
        path: "/darkxiyou/mid",
        query: {
          key: key,
        },
      })
    },
    // mycard
    payByMyCard(orderNum) {
      payMycard({
        order_num: orderNum,
        user_id: this.user_id,
        type: 'official',
        return_url: "darkxiyou",
        lang: this.lang
      }).then((res) => {
        window.location.href = res.url
        Toast.clear(true)
        Toast.loading({
          message: "Loading",
          forbidClick: true,
          duration: 3000,
        });
      }).catch((e) => {
        this.$toast.fail(e.message)
      });
    },
  },
};
</script>

<style >
.product_Name {
  font-size: 16px;
  text-align: center;
  padding: 10px 0;
}

.colorRed{
  color: red;
}

.pay_icon {
  width: 35px;
  height: 44px;
  float: left;
}

.pay_desc {
  float: left;
  display: block;
  line-height: 44px;
  margin-left: 5px;
}

.pay_paypal_desc1 {
  display: block;
  line-height: 13px;
  margin-left: 20px;
  /*text-align: center;*/
  font-size: 13px;
  /*width: 262px;*/
}

.pay_paypal_tip {
  /*color: red;*/
}

.title_coupon {
  /*margin-right: 5px;*/
}

/* .van-coupon-list__list {
  height: 100% !important;
} */

.rx-bc {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.optionText {
  padding: 10px;
  text-align: center;

  line-height: 24px;
}</style>
